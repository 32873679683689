import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "constainer"
  }, [_c("div", {
    staticClass: "constainer-header"
  }, [_c("a-form", {
    attrs: {
      labelCol: {
        style: "width: 120px"
      },
      form: _vm.editorForm
    }
  }, [_c("a-form-item", {
    attrs: {
      label: "群等级",
      name: "groupIndividualLevel"
    }
  }, [_c("a-select", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["gradeid", {
        rules: [{
          required: true,
          message: "请输入群等级"
        }],
        initialValue: _vm.currentFormData.gradeid
      }],
      expression: "[\n            'gradeid',\n            {\n              rules: [{ required: true, message: '请输入群等级' }],\n              initialValue: currentFormData.gradeid,\n            },\n          ]"
    }],
    staticStyle: {
      width: "120px"
    }
  }, _vm._l(_vm.item.gradeId, function (option) {
    return _c("a-select-option", {
      key: option.id,
      attrs: {
        value: option.id
      }
    }, [_vm._v(" " + _vm._s(option.name) + " ")]);
  }), 1)], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("a-form-item", {
    staticStyle: {
      flex: "1"
    },
    attrs: {
      label: "升级群等级"
    }
  }, [_c("a-input", {
    directives: [{
      name: "integer-only",
      rawName: "v-integer-only"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["minintegral", {
        rules: [{
          required: true,
          message: "请输入最小经验值"
        }],
        initialValue: _vm.currentFormData.minintegral
      }],
      expression: "[\n              'minintegral',\n              {\n                rules: [{ required: true, message: '请输入最小经验值' }],\n                initialValue: currentFormData.minintegral,\n              },\n            ]"
    }],
    staticStyle: {
      width: "130px"
    },
    attrs: {
      type: "number"
    },
    on: {
      input: _vm.validateMinIntegral
    }
  })], 1), _c("span", {
    staticStyle: {
      margin: "0 4px",
      "margin-bottom": "20px"
    }
  }, [_vm._v("-")]), _c("a-form-item", {
    staticStyle: {
      flex: "1"
    },
    attrs: {
      label: ""
    }
  }, [_c("a-input", {
    directives: [{
      name: "integer-only",
      rawName: "v-integer-only"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["maxintegral", {
        rules: [{
          required: true,
          message: "请输入最大经验值"
        }],
        initialValue: _vm.currentFormData.maxintegral
      }],
      expression: "[\n              'maxintegral',\n              {\n                rules: [{ required: true, message: '请输入最大经验值' }],\n                initialValue: currentFormData.maxintegral,\n              },\n            ]"
    }],
    staticStyle: {
      width: "130px"
    },
    attrs: {
      type: "number"
    },
    on: {
      input: _vm.validateMinIntegral
    }
  })], 1)], 1), _vm.minIntegralError || _vm.isError ? _c("div", {
    staticClass: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.minIntegralError ? _vm.minIntegralError : _vm.isErrorText) + " ")]) : _vm._e(), _c("a-form-item", {
    attrs: {
      label: "等级对应奖励"
    }
  }, [_c("a-input", {
    directives: [{
      name: "integer-only",
      rawName: "v-integer-only"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["gradereward", {
        rules: [{
          required: true,
          message: "请输入等级对应奖励"
        }],
        initialValue: _vm.currentFormData.gradereward
      }],
      expression: "[\n            'gradereward',\n            {\n              rules: [{ required: true, message: '请输入等级对应奖励' }],\n              initialValue: currentFormData.gradereward,\n            },\n          ]"
    }],
    staticStyle: {
      width: "130px"
    },
    attrs: {
      type: "number"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v("CBK")])], 1), _c("a-form-item", {
    attrs: {
      label: "发言活跃奖励"
    }
  }, [_c("a-input", {
    directives: [{
      name: "integer-only",
      rawName: "v-integer-only"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["speechreward", {
        rules: [{
          required: true,
          message: "请输入发言活跃奖励"
        }],
        initialValue: _vm.currentFormData.speechreward
      }],
      expression: "[\n            'speechreward',\n            {\n              rules: [{ required: true, message: '请输入发言活跃奖励' }],\n              initialValue: currentFormData.speechreward,\n            },\n          ]"
    }],
    staticStyle: {
      width: "130px"
    },
    attrs: {
      type: "number"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v("CBK")])], 1), _c("a-form-item", {
    attrs: {
      label: "升级需要的cbk"
    }
  }, [_c("a-input", {
    directives: [{
      name: "integer-only",
      rawName: "v-integer-only"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["amount", {
        rules: [{
          required: true,
          message: "请输入升级需要的cbk"
        }],
        initialValue: _vm.currentFormData.amount
      }],
      expression: "[\n            'amount',\n            {\n              rules: [{ required: true, message: '请输入升级需要的cbk' }],\n              initialValue: currentFormData.amount,\n            },\n          ]"
    }],
    staticStyle: {
      width: "130px"
    },
    attrs: {
      type: "number"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v("CBK")])], 1), _c("a-form-item", {
    attrs: {
      label: "有效新用户奖励"
    }
  }, [_c("a-input", {
    directives: [{
      name: "integer-only",
      rawName: "v-integer-only"
    }, {
      name: "decorator",
      rawName: "v-decorator",
      value: ["activenewuserreward", {
        rules: [{
          required: true,
          message: "请输入有效新用户奖励"
        }],
        initialValue: _vm.currentFormData.activenewuserreward
      }],
      expression: "[\n            'activenewuserreward',\n            {\n              rules: [{ required: true, message: '请输入有效新用户奖励' }],\n              initialValue: currentFormData.activenewuserreward,\n            },\n          ]"
    }],
    staticStyle: {
      width: "130px"
    },
    attrs: {
      type: "number"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v("CBK")])], 1), _c("a-form-item", {
    attrs: {
      label: "群等级icon"
    }
  }, [_vm.loading ? _c("div", {
    staticClass: "loading-overlay"
  }, [_vm._v(" 加载中... ")]) : _vm._e(), _c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["iconurl", {
        // rules: [{ required: true, message: '请上传封面图' }],
        initialValue: _vm.currentFormData.iconurl
      }],
      expression: "[\n            'iconurl',\n            {\n              // rules: [{ required: true, message: '请上传封面图' }],\n              initialValue: currentFormData.iconurl,\n            },\n          ]"
    }],
    attrs: {
      classname: "uploadImg ".concat(_vm.loading ? "loadingShow" : ""),
      listType: "picture-card",
      max: 1,
      imgProportion: [337, 132],
      memorySize: "1024kb",
      force: false,
      showTips: false,
      accept: ".jpg,.jpeg,.png,.gif"
    },
    on: {
      uploadSuccess: _vm.handleUploadSuccess,
      uploadError: _vm.handleUploadError,
      uoloadCope: _vm.handleUpload,
      previewImg: _vm.previewImg
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "群等级背景"
    }
  }, [_vm.loadingBg ? _c("div", {
    staticClass: "loading-overlay"
  }, [_vm._v(" 加载中... ")]) : _vm._e(), _c("UploadImg", {
    directives: [{
      name: "decorator",
      rawName: "v-decorator",
      value: ["backendurl", {
        rules: [{
          required: true,
          message: "请上传群背景图"
        }],
        initialValue: _vm.currentFormData.backendurl
      }],
      expression: "[\n            'backendurl',\n            {\n              rules: [{ required: true, message: '请上传群背景图' }],\n              initialValue: currentFormData.backendurl,\n            },\n          ]"
    }],
    attrs: {
      classname: "uploadImg2 ".concat(_vm.loadingBg ? "loadingShow" : ""),
      listType: "picture-card",
      max: 1,
      imgProportion: [337, 132],
      memorySize: "1024kb",
      force: false,
      showTips: false,
      accept: ".jpg,.jpeg,.png,.gif"
    },
    on: {
      uploadSuccess: _vm.handleUploadSuccess2,
      uploadError: _vm.handleUploadError2,
      uoloadCope: _vm.handleUpload2,
      previewImg: _vm.previewImg
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between"
    }
  }, [_vm.updateStatus == 0 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#f5f5f5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s(_vm.editId ? "保存" : "创建"))])], 1) : _vm._e(), _vm.updateStatus == 1 ? _c("span", [_c("a-button", {
    staticClass: "btn btn_l",
    staticStyle: {
      background: "#f5f5f5",
      border: "0"
    },
    attrs: {
      size: "large"
    },
    on: {
      click: _vm.cancle
    }
  }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
    staticClass: "btn btn_r",
    staticStyle: {
      "margin-left": "10px"
    },
    attrs: {
      size: "large",
      type: "primary",
      loading: _vm.submitLoading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v(_vm._s("确定"))])], 1) : _vm._e()])], 1)], 1)]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };