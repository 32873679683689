var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "justify-content": "space-between",
      "margin-bottom": "20px",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      gap: "20px",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-right": "8px"
    }
  }, [_vm._v("超过3个群")]), _c("a-input", {
    staticStyle: {
      width: "180px"
    },
    model: {
      value: _vm.config.beyondbasenum,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "beyondbasenum", $$v);
      },
      expression: "config.beyondbasenum"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v("CBK")])], 1), _c("div", {
    staticStyle: {
      display: "flex",
      "align-items": "center"
    }
  }, [_c("div", {
    staticStyle: {
      "margin-right": "8px"
    }
  }, [_vm._v("超过3个群的倍数")]), _c("a-input", {
    staticStyle: {
      width: "180px"
    },
    model: {
      value: _vm.config.basetimes,
      callback: function callback($$v) {
        _vm.$set(_vm.config, "basetimes", $$v);
      },
      expression: "config.basetimes"
    }
  }), _c("span", {
    staticStyle: {
      "margin-left": "8px"
    }
  }, [_vm._v("CBK")])], 1), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["groupManagementMultiple:btn:modify"],
      expression: "['groupManagementMultiple:btn:modify']"
    }],
    attrs: {
      type: "link",
      loading: _vm.modifyLoaidng
    },
    on: {
      click: _vm.handleModify
    }
  }, [_vm._v(_vm._s("修改"))])], 1), _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["groupManagement:btn:add"],
      expression: "['groupManagement:btn:add']"
    }],
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      type: "primary",
      size: "large"
    },
    on: {
      click: _vm.handleAdd
    }
  }, [_vm._v("添加")])], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return i;
      },
      scroll: {
        x: 1580
      },
      loading: _vm.loading
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(actions, row) {
        return [_c("div", {
          staticClass: "flex",
          staticStyle: {
            width: "150px",
            margin: "0 auto"
          }
        }, [_c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["groupManagement:btn:edit"],
            expression: "['groupManagement:btn:edit']"
          }],
          staticStyle: {
            "min-width": "60px"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("edit", row);
            }
          }
        }, [_vm._v("编辑")]), _c("a-button", {
          directives: [{
            name: "hasPermission",
            rawName: "v-hasPermission",
            value: ["groupManagement:btn:del"],
            expression: "['groupManagement:btn:del']"
          }],
          staticStyle: {
            color: "#ff4d4f",
            "min-width": "60px"
          },
          attrs: {
            type: "link"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("del", row);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }, {
      key: "gradeExpirence",
      fn: function fn(gradeExpirence, row) {
        var _row$minintegral, _row$maxintegral;
        return [_vm._v(" " + _vm._s((_row$minintegral = row.minintegral) !== null && _row$minintegral !== void 0 ? _row$minintegral : "**") + " "), _c("span", {
          staticStyle: {
            margin: "0 6px"
          }
        }, [_vm._v("-")]), _vm._v(" " + _vm._s((_row$maxintegral = row.maxintegral) !== null && _row$maxintegral !== void 0 ? _row$maxintegral : "**") + " ")];
      }
    }, {
      key: "backendurl",
      fn: function fn(backendurl, row) {
        return [row.backendurl ? _c("img", {
          staticStyle: {
            width: "100px",
            height: "100px"
          },
          attrs: {
            src: row.backendurl,
            alt: "背景图"
          }
        }) : _c("div", {
          staticStyle: {
            width: "100px",
            height: "100px",
            "background-color": "#b38d8d",
            "border-radius": "50px"
          }
        })];
      }
    }, {
      key: "iconurl",
      fn: function fn(backendurl, row) {
        return [row.iconurl ? _c("img", {
          staticStyle: {
            width: "100px",
            height: "100px"
          },
          attrs: {
            src: row.iconurl,
            alt: "群图标"
          }
        }) : _c("div", {
          staticStyle: {
            width: "100px",
            height: "100px",
            "background-color": "#b38d8d",
            "border-radius": "50px"
          }
        })];
      }
    }, {
      key: "amount",
      fn: function fn(amount, row) {
        return [_vm._v(" " + _vm._s(row.amount ? parseFloat(row.amount) : "--") + " ")];
      }
    }, {
      key: "isstatus",
      fn: function fn(isstatus, row) {
        return [row.status === 1 ? _c("span", {
          staticStyle: {
            color: "antiquewhite"
          }
        }, [_vm._v("失效")]) : _c("span", {
          staticStyle: {
            color: "green"
          }
        }, [_vm._v("正常")])];
      }
    }, {
      key: "currencytype",
      fn: function fn(currencytype, row) {
        return [_vm._v(" " + _vm._s(row.currencytype == 1 ? "USDT" : "CBK") + " ")];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onShowSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1), _c("DPopup", {
    attrs: {
      title: _vm.versionTitle,
      comVisible: _vm.addVersionVisible
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("AddLevel", {
          attrs: {
            updateStatus: _vm.updateStatus,
            editId: _vm.editId,
            currentFormData: _vm.currentFormData
          },
          on: {
            handleAddCancle: _vm.handleAddCancle,
            handleAddSubmit: _vm.handleAddSubmitSuccess
          }
        })];
      },
      proxy: true
    }])
  }), _c("DPopup", {
    attrs: {
      title: "",
      comVisible: _vm.delVisible
    },
    scopedSlots: _vm._u([{
      key: "form",
      fn: function fn() {
        return [_c("div", {
          staticStyle: {
            display: "flex",
            "justify-content": "center"
          }
        }, [_c("p", {
          staticStyle: {
            "font-size": "18px",
            "margin-top": "20px"
          }
        }, [_vm._v(" 确定删除" + _vm._s(_vm.gradeContent) + "等级配置吗？"), _c("br"), _vm._v(" 删除后会影响客户端数据！ ")])]), _c("div", {
          staticStyle: {
            display: "flex",
            "justify-content": "flex-end",
            margin: "10px",
            gap: "10px"
          }
        }, [_c("a-button", {
          staticClass: "btn btn_l",
          staticStyle: {
            background: "#f5f5f5",
            border: "0",
            flex: "1"
          },
          attrs: {
            size: "large"
          },
          on: {
            click: _vm.cancle
          }
        }, [_vm._v(_vm._s("取消"))]), _c("a-button", {
          staticClass: "btn btn_r",
          staticStyle: {
            "margin-left": "10px",
            flex: "1"
          },
          attrs: {
            size: "large",
            type: "primary"
          },
          on: {
            click: _vm.confirm
          }
        }, [_vm._v(_vm._s("确定"))])], 1)];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };