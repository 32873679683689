import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.object.to-string.js";
import Mixin from "@/utils/mixin";
import { columns } from "./config/index";
import { groupManagementApi } from "@/api";
import AddLevel from "./components/AddLevel.vue";
// import UpdateStatus from "./components/UpdateStatus.vue";
export default {
  name: "versionManagement",
  components: {
    AddLevel: AddLevel
    // UpdateStatus,
  },
  data: function data() {
    return {
      updateVisible: false,
      editId: "",
      loading: false,
      columns: columns,
      total: 0,
      page: 1,
      size: 20,
      dataSource: [],
      versionTitle: "",
      addVersionVisible: false,
      updateStatus: "",
      config: {
        beyondbasenum: "",
        //超过3个群
        basetimes: "" //倍数
      },

      //编辑数据
      currentFormData: {},
      delVisible: false,
      gradeContent: "",
      modifyLoaidng: false
    };
  },
  mixins: [Mixin],
  computed: {
    typeText: function typeText() {
      return function (val) {
        switch (val) {
          case 1:
            return "IOS";
          case 2:
            return "安卓";
          default:
            return "-";
        }
      };
    },
    forceupdateText: function forceupdateText() {
      return function (val) {
        switch (val) {
          case 0:
            return "否";
          case 1:
            return "是";
          default:
            return "-";
        }
      };
    },
    statusText: function statusText() {
      return function (val) {
        switch (val) {
          case 0:
            return "生效中";
          case 1:
            return "已过期";
          default:
            return "-";
        }
      };
    }
  },
  created: function created() {
    this.getList();
    this.getGroupSetting();
  },
  methods: {
    copy: function copy() {
      this.toast("复制成功", "success");
    },
    handleStop: function handleStop(val) {
      this.editId = val.id;
      this.updateVisible = true;
    },
    handleUpdateCancle: function handleUpdateCancle() {
      this.updateVisible = false;
    },
    handleUpdateSubmitSuccess: function handleUpdateSubmitSuccess() {
      this.getList();
      this.updateVisible = false;
    },
    handleAddCancle: function handleAddCancle() {
      this.addVersionVisible = false;
    },
    handleAddSubmitSuccess: function handleAddSubmitSuccess() {
      this.getList();
      this.addVersionVisible = false;
    },
    handleUpdateDetail: function handleUpdateDetail(val) {
      this.versionTitle = val.status == 0 ? "添加群等级配置" : "编辑群等级配置";
      this.updateStatus = "1";
      this.editId = val.id;
      this.addVersionVisible = true;
      this.currentFormData = _objectSpread(_objectSpread({}, val), {}, {
        amount: parseFloat(val.amount),
        speechreward: parseFloat(val.speechreward),
        gradereward: parseFloat(val.gradereward),
        activenewuserreward: parseFloat(val.activenewuserreward),
        minintegral: parseFloat(val.minintegral),
        maxintegral: parseFloat(val.maxintegral)
      });
    },
    getList: function getList() {
      var _this = this;
      this.loading = true;
      var _params = {
        size: this.size,
        page: this.page
      };
      groupManagementApi.findpage(_params).then(function (res) {
        _this.loading = false;
        _this.dataSource = res.list;
        _this.total = res.total;
      }).catch(function () {
        _this.dataSource = [];
      }).finally(function () {
        setTimeout(function () {
          _this.loading = false;
        }, 3000);
      });
    },
    onPageSizeChange: function onPageSizeChange(page) {
      this.page = page;
      this.getList();
    },
    onShowSizeChange: function onShowSizeChange(page, size) {
      this.page = 1;
      this.size = size;
      this.getList();
    },
    handleAdd: function handleAdd() {
      this.currentFormData = {};
      this.editId = "";
      this.updateStatus = "";
      this.versionTitle = "添加群等级配置";
      this.addVersionVisible = true;
    },
    getGroupSetting: function getGroupSetting() {
      var _this2 = this;
      groupManagementApi.groupLevelConfig().then(function (res) {
        if (res.beyondbasenum) {
          _this2.config.beyondbasenum = res.beyondbasenum;
          _this2.config.basetimes = res.basetimes;
        }
      }).catch(function () {});
    },
    //修改群配置
    handleButton: function handleButton(type, val) {
      this.currentFormData = _objectSpread({}, val);
      if (type == "edit") {
        this.handleUpdateDetail(val);
      } else {
        this.delVisible = true;
        // this.handleDelete(val);
        this.gradeContent = val.grade;
      }
    },
    handleModify: function handleModify() {
      var _this3 = this;
      this.modifyLoaidng = true;
      groupManagementApi.groupLevelConfigEdit(_objectSpread({}, this.config)).then(function () {
        _this3.toast("操作成功", "success");
      }).catch(function () {
        _this3.toast("操作失败", "error");
      }).finally(function () {
        _this3.modifyLoaidng = false;
      });
    },
    //删除群配置
    handleDelete: function handleDelete(val) {
      var _this4 = this;
      groupManagementApi.groupDelete({
        id: val.id
      }).then(function () {
        _this4.getList();
        _this4.toast("操作成功", "success");
      }).catch(function () {
        _this4.toast("操作失败", "error");
      });
    },
    confirm: function confirm() {
      this.handleDelete(this.currentFormData);
      this.delVisible = false;
    },
    cancle: function cancle() {
      this.delVisible = false;
      // this.$message.error('Click on No');
    }
  }
};